import React from 'react';
import "@/styles/globals.css";
import ErrorBoundary from "@/components/ErrorBoundary";
import type { AppProps } from "next/app";
import Head from 'next/head'

if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
  const ReactDOM = require('react-dom');
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

function WebsiteApp({ Component, pageProps }: AppProps) {

  return (
    <ErrorBoundary>
      <Head>
        <meta name="viewport" content="width=device-width,viewport-fit=cover" />
      </Head>
      <Component {...pageProps} />
    </ErrorBoundary>
  );
}

export default WebsiteApp;